const CORPORA = [
  {
    name: "Catalan web corpus MaCoCu-ca 1.0",
    source: "ca",
    target: "",
    size: 4720,
    tokens: 1736578493,
    url: "http://hdl.handle.net/11356/1837",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-ca/en/day",
    language: "Catalan",
    tags: ["V1","Release 2"],
  },
  {
    name: "Ukrainian web corpus MaCoCu-uk 1.0",
    source: "uk",
    target: "",
    size: 24580,
    tokens: 6181945683,
    url: "http://hdl.handle.net/11356/1838",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-uk/en/day",
    language: "Ukrainian",
    tags: ["V1","Release 2"],
  },
  {
    name: "Greek web corpus MaCoCu-el 1.0",
    source: "el",
    target: "",
    size: 16230,
    tokens: 4384614674,
    url: "http://hdl.handle.net/11356/1839",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-el/en/day",
    language: "Greek",
    tags: ["V1","Release 2"],
  },
  {
    name: "Icelandic web corpus MaCoCu-is 2.0",
    source: "is",
    target: "",
    size: 2480,
    tokens: 887198687,
    url: "http://hdl.handle.net/11356/1805",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-is/en/day",
    language: "Icelandic",
    tags: ["V2","Release 2"],
  },  
  {
    name: "Turkish web corpus MaCoCu-tr 2.0",
    source: "tr",
    target: "",
    size: 15070,
    tokens: 4344850253, 
    url: "http://hdl.handle.net/11356/1802",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-tr/en/day",
    language: "Turkish",
    tags: ["V2","Release 2"],
  },
    {
    name: "Bulgarian web corpus MaCoCu-bg 2.0",
    source: "bg",
    target: "",
    size: 12060,
    tokens: 3506223084, 
    url: "http://hdl.handle.net/11356/1800",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-bg/en/day",
    language: "Bulgarian",
    tags: ["V2","Release 2"],
  },
  {
    name: "Croatian web corpus MaCoCu-hr 2.0",
    source: "hr",
    target: "",
    size: 7120,
    tokens: 2363710130, 
    url: "http://hdl.handle.net/11356/1806",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-hr/en/day",
    language: "Croatian",
    tags: ["V2","Release 2"],
  },
  {
    name: "Slovene web corpus MaCoCu-sl 2.0",
    source: "sl",
    target: "",
    size: 5570,
    tokens: 1920089135, 
    url: "http://hdl.handle.net/11356/1795",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-sl/en/day",
    language: "Slovene",
    tags: ["V2","Release 2"],
  },
  {
    name: "Macedonian web corpus MaCoCu-mk 2.0",
    source: "mk",
    target: "",
    size: 1790,
    tokens: 524069254, 
    url: "http://hdl.handle.net/11356/1801",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-mk/en/day",
    language: "Macedonian",
    tags: ["V2","Release 2"],
  },
  
  {
    name: "Maltese web corpus MaCoCu-mt 2.0",
    source: "mt",
    target: "",
    size: 1070,
    tokens: 347855619, 
    url: "http://hdl.handle.net/11356/1803",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-mt/en/day",
    language: "Maltese",
    tags: ["V2","Release 2"],
  },
  {
    name: "Albanian web corpus MaCoCu-sq 1.0",
    source: "sq",
    target: "",
    size: 1630,
    tokens: 625726547, 
    url: "http://hdl.handle.net/11356/1804",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-sq/en/day",
    language: "Albanian",
    tags: ["V1","Release 2"],
  },
  {
    name: "Serbian web corpus MaCoCu-sr 1.0",
    source: "sr",
    target: "",
    size: 7620,
    tokens: 2491019884, 
    url: "http://hdl.handle.net/11356/1807",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-sr/en/day",
    language: "Serbian",
    tags: ["V1","Release 2"],
  },
  {
    name: "Bosnian web corpus MaCoCu-bs 1.0",
    source: "bs",
    target: "",
    size: 2210,
    tokens: 730342880, 
    url: "http://hdl.handle.net/11356/1808",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-bs/en/day",
    language: "Bosnian",
    tags: ["V1","Release 2"],
  },
  {
    name: "Montenegrin web corpus MaCoCu-cnr 1.0",
    source: "me",
    target: "",
    size: 500,
    tokens: 586804,
    url: "http://hdl.handle.net/11356/1809",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-me/en/day",
    language: "Montenegrin",
    tags: ["V1","Release 2"],
  },
  {
    name: "Catalan-English parallel corpus MaCoCu-ca-en 1.0",
    source: "en",
    target: "ca",
    size: 934,
    sentences: 3130519, 
    url: "http://hdl.handle.net/11356/1857",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-ca/en/day",
    language: "English-Catalan",
    tags: ["V1","Release 2"],

  },
  {
    name: "Greek-English parallel corpus MaCoCu-el-en 1.0",
    source: "en",
    target: "el",
    size: 1210,
    sentences: 3583974 , 
    url: "http://hdl.handle.net/11356/1856",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-el/en/day",
    language: "English-Greek",
    tags: ["V1","Release 2"],

  },
  {
    name: "Ukrainian-English parallel corpus MaCoCu-uk-en 1.0",
    source: "en",
    target: "uk",
    size: 2180,
    sentences: 6406287, 
    url: "http://hdl.handle.net/11356/1858",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-uk/en/day",
    language: "English-Ukrainian",
    tags: ["V1","Release 2"],
  },
 

  {
    name: "Maltese-English parallel corpus MaCoCu-mt-en 2.0",
    source: "en",
    target: "mt",
    size: 291,
    sentences: 925326, 
    url: "http://hdl.handle.net/11356/1811",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-mt/en/day",
    language: "English-Maltese",
    tags: ["V2","Release 2"],

  },
  {
    name: "Turkish-English parallel corpus MaCoCu-tr-en 2.0",
    source: "en",
    target: "tr",
    size: 611,
    sentences: 1646739,
    url: "http://hdl.handle.net/11356/1816",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-tr/en/day",
    language: "English-Turkish",
    tags: ["V2","Release 2"],
  },
  {
    name: "Bulgarian-English parallel corpus MaCoCu-bg-en 2.0",
    source: "en",
    target: "bg",
    size: 586,
    sentences: 1760777, 
    url: "http://hdl.handle.net/11356/1815",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-bg/en/day",
    language: "English-Bulgarian",
    tags: ["V2","Release 2"],
  },
  {
    name: "Croatian-English parallel corpus MaCoCu-hr-en 2.0",
    source: "en",
    target: "hr",
    size: 690,
    sentences: 2266004, 
    url: "http://hdl.handle.net/11356/1814",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-hr/en/day",
    language: "English-Croatian",
    tags: ["V2","Release 2"],
  },
  {
    name: "Slovene-English parallel corpus MaCoCu-sl-en 2.0",
    source: "en",
    target: "sl",
    size: 569,
    sentences: 1875516,
    url: "http://hdl.handle.net/11356/1813",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-sl/en/day",
    language: "English-Slovene",
    tags: ["V2","Release 2"],
  },
  {
    name: "Macedonian-English parallel corpus MaCoCu-mk-en 2.0",
    source: "en",
    target: "mk",
    size: 134,
    sentences: 376747, 
    url: "http://hdl.handle.net/11356/1817",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-mk/en/day",
    language: "English-Macedonian",
    tags: ["V2","Release 2"],
  },
  {
    name: "Icelandic-English parallel corpus MaCoCu-is-en 2.0",
    source: "en",
    target: "is",
    size: 75,
    sentences: 267365, 
    url: "http://hdl.handle.net/11356/1812",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-is/en/day",
    language: "English-Icelandic",
    tags: ["V2","Release 2"],
  },
  {
    name: "Albanian-English parallel corpus MaCoCu-sq-en 1.0",
    source: "en",
    target: "sq",
    size: 158,
    sentences: 507537, 
    url: "http://hdl.handle.net/11356/1818",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-sq/en/day",
    language: "English-Albanian",
    tags: ["V1","Release 1"],
  },
  {
    name: "Serbian-English parallel corpus MaCoCu-sr-en 1.0",
    source: "en",
    target: "sr",
    size: 673,
    sentences: 2068916,
    url: "http://hdl.handle.net/11356/1819",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-sr/en/day",
    language: "English-Serbian",
    tags: ["V1","Release 1"],
  },
  {
    name: "Bosnian-English parallel corpus MaCoCu-bs-en 1.0",
    source: "en",
    target: "bs",
    size: 155,
    sentences: 501239,
    url: "http://hdl.handle.net/11356/1820",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-bs/en/day",
    language: "English-Bosnian",
    tags: ["V1","Release 1"],
  },
  {
    name: "Montenegrin-English parallel corpus MaCoCu-cnr-en 1.0",
    source: "en",
    target: "me",
    size: 74,
    sentences: 218671,
    url: "http://hdl.handle.net/11356/1821",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-v2-en-me/en/day",
    language: "English-Montenegrin",
    tags: ["V1","Release 1"],
  },
 
  {
    name: "Turkish web corpus MaCoCu-tr 1.0",
    source: "tr",
    target: "",
    size: 14920,
    tokens: 513454136, 
    url: "http://hdl.handle.net/11356/1514",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-tr/en/day",
    language: "Turkish",
    tags: ["V1","Release 1"],

  },
  {
    name: "Bulgarian web corpus MaCoCu-bg 1.0",
    source: "bg",
    target: "",
    size: 11740,
    tokens : 3508930378,
    url: "http://hdl.handle.net/11356/1515",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-bg/en/day",
    language: "Bulgarian",
    tags: ["V1","Release 1"],
  },
  {
    name: "Croatian web corpus MaCoCu-hr 1.0",
    source: "hr",
    target: "",
    size: 7010,
    tokens: 2318360453, 
    url: "http://hdl.handle.net/11356/1516",
    corsetUrl: "https://corset.paracrawl.eu/search/europat-en-hr/en/day",
    language: "Croatian",
    tags: ["V1","Release 1"],
  },
  {
    name: "Slovene web corpus MaCoCu-sl 1.0",
    source: "sl",
    target: "",
    size: 5240,
    tokens: 1779051582, 
    url: "http://hdl.handle.net/11356/1517",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-sl/en/day",
    language: "Slovene",
    tags: ["V1","Release 1"],
  },
  {
    name: "Macedonian web corpus MaCoCu-mk 1.0",
    source: "mk",
    target: "",
    size: 1740,
    tokens: 524063234,
    url: "http://hdl.handle.net/11356/1512",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-mk/en/day",
    language: "Macedonian",
    tags: ["V1","Release 1"],
  },
  {
    name: "Icelandic web corpus MaCoCu-is 1.0",
    source: "is",
    target: "",
    size: 1810,
    tokens: 644513256, 
    url: "http://hdl.handle.net/11356/1518",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-is/en/day",
    language: "Icelandic",
    tags: ["V1","Release 1"],
  },
  {
    name: "Maltese web corpus MaCoCu-mt 1.0",
    source: "mt",
    target: "",
    size: 1060,
    tokens: 347857107, 
    url: "http://hdl.handle.net/11356/1519",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-mt/en/day",
    language: "Maltese",
    tags: ["V1","Release 1"],
  },
  {
    name: "Turkish-English parallel corpus MaCoCu-tr-en 1.0",
    source: "en",
    target: "tr",
    size: 1510,
    sentences: 10323996,
    url: "http://hdl.handle.net/11356/1520",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-tr/en/day",
    language: "English-Turkish",
    tags: ["V1","Release 1"],
  },
  {
    name: "Bulgarian-English parallel corpus MaCoCu-bg-en 1.0",
    source: "en",
    target: "bg",
    size: 498.27,
    sentences: 3857653,
    url: "http://hdl.handle.net/11356/1521",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-bg/en/day",
    language: "English-Bulgarian",
    tags: ["V1","Release 1"],
  },
  {
    name: "Croatian-English parallel corpus MaCoCu-hr-en 1.0",
    source: "en",
    target: "hr",
    size: 374,
    sentences: 3097282,
    url: "http://hdl.handle.net/11356/1522",
    corsetUrl: "https://corset.paracrawl.eu/search/europat-en-hr/en/day",
    language: "English-Croatian",
    tags: ["V1","Release 1"],
  },
  {
    name: "Slovene-English parallel corpus MaCoCu-sl-en 1.0",
    source: "en",
    target: "sl",
    size: 377.34,
    sentences: 3176311,
    url: "http://hdl.handle.net/11356/1523",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-sl/en/day",
    language: "English-Slovene",
    tags: ["V1","Release 1"],

  },
  {
    name: "Macedonian-English parallel corpus MaCoCu-mk-en 1.0",
    source: "en",
    target: "mk",
    size: 72.77,
    sentences: 478059,
    url: "http://hdl.handle.net/11356/1513",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-mk/en/day",
    language: "English-Macedonian",
    tags: ["V1","Release 1"],

  },
  {
    name: "Icelandic-English parallel corpus MaCoCu-is-en 1.0",
    source: "en",
    target: "is",
    size: 39.43,
    sentences: 355100,
    url: "http://hdl.handle.net/11356/1524",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-is/en/day",
    language: "English-Icelandic",
    tags: ["V1","Release 1"],

  },
  {
    name: "Maltese-English parallel corpus MaCoCu-mt-en 1.0",
    source: "en",
    target: "mt",
    size: 186.4,
    sentences: 1231654,
    url: "http://hdl.handle.net/11356/1525",
    corsetUrl: "https://corset.paracrawl.eu/search/macocu-en-mt/en/day",
    language: "English-Maltese",
    tags: ["V1","Release 1"],

  },
  {
    name: "DSI-enriched ParaCrawl 9 en-es corpus",
    source: "en",
    target: "es",
    size: 176820,
    sentences: 269394967,
    url: "http://hdl.handle.net/11356/1526",
    corsetUrl: "https://corset.paracrawl.eu/search/paracrawl-en-es/en/day",
    tags: ["V1","Release 1", "DSI"],
    language: "English-Spanish",
  },
  {
    name: "DSI-enriched ParaCrawl 9 en-nl corpus",
    source: "en",
    target: "nl",
    size: 55540,
    sentences: 89135870,
    url: "http://hdl.handle.net/11356/1527",
    corsetUrl: "https://corset.paracrawl.eu/search/paracrawl-en-nl/en/day",
    tags: ["V1","Release 1", "DSI"],
    language: "English-Dutch",
  },
];

export default CORPORA;
