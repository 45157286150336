import PlayIcon from "../../icons/play.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { Toaster, toast } from "react-hot-toast";
import CopyIcon from "../../icons/copy.svg";

import "./paper.styles.css";

export default function Paper(props) {
  const {
    tags,
    date,
    title,
    base,
    authors,
    type,
    pdf,
    bibtex,
    paperLink,
    presentationUrl,
  } = props;

  return (
    <div className="paper">
      <div className="tags-and-date">
        <div className="tags">
          {tags.map((tag) => (
            <div className="tag">{tag}</div>
          ))}
        </div>
        <h4 className="paper-date">{date}</h4>
      </div>
      {paperLink ? (
        <a href={paperLink} className="paper-link" target="_blank">
          <h1 className="paper-title">{title}</h1>
        </a>
      ) : (
        <h1 className="paper-title">{title}</h1>
      )}

      <h3 className="paper-base">{base}</h3>
      <div className="authors-and-download-buttons">
        <h4 className="authors">{authors}</h4>
        {props.type === "paper" && (
          <div className="paper-download-buttons">
            <a
              target="_blank"
              rel="noreferrer"
              className="download-paper-pdf"
              href={pdf}
            >
              PDF
            </a>
            <CopyToClipboard
              text={decodeURIComponent(decodeURIComponent(bibtex))}
            >
              <div
                onClick={
                  bibtex ? () => toast.success("Copied to clipboard!") : ""
                }
                target="_blank"
                rel="noreferrer"
                className={
                  bibtex
                    ? "download-paper-bibtex"
                    : "download-paper-bibtex button-disabled"
                }
              >
                BibTex{" "}
                {bibtex ? (
                  <span>
                    <img className="copy-icon" src={CopyIcon} alt="copy-icon" />
                  </span>
                ) : (
                  ""
                )}
              </div>
            </CopyToClipboard>
            <Toaster />
          </div>
        )}
        {type === "presentation" && (
          <a
            className="watch-presentation-link"
            href={presentationUrl}
            target="_blank"
            rel="noreferrer"
          >
            <div className="presentation-button">
              <img src={PlayIcon} className="play-icon" alt="play-icon" />
              <p>Watch the presentation</p>
            </div>
          </a>
        )}
      </div>
    </div>
  );
}
