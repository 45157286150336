import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/homepage/homepage.page.jsx";
import TeamPage from "./pages/team-page/team-page";
import PapersPage from "./pages/papers-page/papers-page";
import ScrollToTop from "./components/scroll-to-top/scrolltotop";
import WorkshopPage from "./pages/workshop-page/workshop-page";

import "./App.css";

function App() {
  return (
    <Router>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/team-page" element={<TeamPage />} />
        <Route path="/papers-page" element={<PapersPage />} />
        <Route path="/workshop" element={<WorkshopPage />} />
      </Routes>
    </Router>
  );
}

export default App;
