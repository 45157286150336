import EUFlag from "../../assets/eu_flag.svg";
import LogoJSI from "../../assets/logo_jsi.png";
import LogoPrompsit from "../../assets/logo_prompsit.png";
import LogoRug from "../../assets/logo_rug.png";
import LogoUA from "../../assets/logo_ua.png";

import "./footer.styles.css";

function Footer() {
  return (
    <div className="footer-wrapper-home">
      <div className="footer-home">
        <div className="footer-information-home">
          <p>
            MaCoCu focuses on collecting monolingual and parallel data from the
            Internet, specially for under-resourced languages and <span> </span>
            <a
              href="https://digital-strategy.ec.europa.eu/en/library/connecting-europe-facility-cef-digital-service-infrastructures"
              target="_blank"
              rel="noreferrer"
            >
              DSI-specific data.
            </a>
          </p>
          <p>
            This action takes place from June 2021 to June 2023 (2 years) and it
            is co-financed by the European Union.
          </p>
          <p>Icons made by SmashIcons and FontAwesome</p>
          <a
            href="https://ec.europa.eu/inea/en/connecting-europe-facility"
            target="_blank"
            rel="noreferrer"
          >
            <div className="eu-financing-p-home">
              <img
                alt="european-union-logo"
                src={EUFlag}
                className="eu-flag-home"
              ></img>
              <p>
                Co-financed by the Connecting Europe Facility of the European
                Union
              </p>
            </div>
          </a>
          <p className="footer-info-last-p">
            This action has received funding from the European Union's
            Connecting Europe Facility 2014-2020 - CEF Telecom, under Grant
            Agreement No. INEA/CEF/ICT/A2020/2278341. This communication
            reflects only the author's view. The Agency is not responsible for
            any use that may be made of the information it contains.
          </p>
        </div>
        <div className="roadmap-footer">
          <div className="milestone-roadmap">
            <div className="dot-roadmap milestone-completed"></div>
            <div className="milestone-text">
              <h4 className="milestone-header">June 2021</h4>
              <p className="milestone-description">MaCoCu is born</p>
            </div>
          </div>
          <div className="milestone-roadmap">
            <div className="dot-roadmap milestone-completed"></div>
            <div className="milestone-text">
              <h4 className="milestone-header">May 2022</h4>
              <p className="milestone-description">
                Data Release 1: monolingual, parallel and domain-specific
                corpora
              </p>
            </div>
          </div>
          <div className="milestone-roadmap">
            <div className="dot-roadmap milestone-completed"></div>
            <div className="milestone-text">
              <h4 className="milestone-header">June 2022</h4>
              <p className="milestone-description">
                Software release 1: Bitextor update with new modules Evaluation
                of Data Release 1
              </p>
            </div>
          </div>
          <div className="milestone-roadmap">
            <div className="dot-roadmap milestone-completed"></div>
            <div className="milestone-text">
              <h4 className="milestone-header">May 2023</h4>
              <p className="milestone-description">
                Data Release 2: monolingual, parallel and domain-specific
                corpora
              </p>
            </div>
          </div>
          <div className="milestone-roadmap">
            <div className="dot-roadmap milestone-completed"></div>
            <div className="milestone-text">
              <h4 className="milestone-header">June 2023</h4>
              <p className="milestone-description last-dot">
                Software release 2: Bitextor update with new modules Evaluation
                of Data Release 2
              </p>
            </div>
          </div>
        </div>
        <div className="partners">
          <p>MaCoCu has been developed by the following partners:</p>
          <div className="partners-logo--container">
            <a
              href="https://cvnet.cpd.ua.es/curriculum-breve/grp/en/transducens/428"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={LogoUA}
                alt="Logo-Universidad-Alicante"
                className="footer-icon"
              />
            </a>
            <a href="https://www.prompsit.com" target="_blank" rel="noreferrer">
              <img
                src={LogoPrompsit}
                alt="Logo-Prompsit"
                className="footer-icon"
              />
            </a>
            <a href="https://kt.ijs.si/" target="_blank" rel="noreferrer">
              <img
                src={LogoJSI}
                alt="Logo-Josef-Stefan-Institute"
                className="footer-icon"
              />
            </a>
            <a
              href="https://www.rug.nl/research/clcg/research/cl/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={LogoRug}
                alt="Logo-Rijksuniversiteit-Groningen"
                className="footer-icon"
              />
            </a>
          </div>{" "}
          <div className="visitor-map">
            <p className="visitor-count-p">Visitor count</p>
            <a href="https://clustrmaps.com/site/1brop" title="Visit tracker">
              <img
                src="//clustrmaps.com/map_v2.png?cl=ffffff&w=a&t=n&d=v9Fooooai1x9gyRWoXOv6fDN46VUx359XmlXTWdjyvQ"
                className="visitor-map-img"
                alt="visitor map"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
