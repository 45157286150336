/* eslint-disable no-useless-escape */
import { useState } from "react";
import "./papers-page.styles.css";
import PAPERS from "../../components/papers.js";
import Paper from "../../components/paper-component/paper.component.jsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Toaster, toast } from "react-hot-toast";
import CopyIcon from "../../icons/copy.svg";
import MacocuPoster from "../../assets/macocu-poster.jpg";
import MacocuPDF from "../../assets/macocu-poster.pdf";
import bibtexEncoded from "./bibtex.js";
import NavbarMobile from "../../components/navbar-mobile/navbar-mobile.component";
import Navbar from "../../components/navbar/navbar.component";
import Footer from "../../components/footer/footer.component";

export default function PapersPage() {
  const [publication, setPublication] = useState(true);
  const [presentation, setPresentation] = useState(false);

  const papers = PAPERS;
  return (
    <>
      <Navbar />
      <div className="papers-page">
        <NavbarMobile />
        <div className="papers-container">
          <div className="cite-macocu">
            <div className="copy-buttons">
              <>
                <h1 className="cite-macocu-title">Cite MaCoCu</h1>
                <div className="copy-title">
                  <h2 className="copy-macocu-title">
                    MaCoCu: Massive collection and curation of monolingual and
                    bilingual data: focus on under-resourced languages{" "}
                  </h2>
                  <CopyToClipboard
                    text="MaCoCu: Massive collection and curation of monolingual and bilingual
            data: focus on under-resourced languages"
                  >
                    <div
                      className="copy-button"
                      onClick={() => toast.success("Copied to clipboard!")}
                    >
                      Copy title{" "}
                      <img
                        className="copy-icon"
                        src={CopyIcon}
                        alt="copy-icon"
                      />
                    </div>
                  </CopyToClipboard>
                  <Toaster />
                </div>
              </>
              <div className="copy-bibtex">
                <p className="bibtex-paragraph">
                  Marta Bañón, Miquel Esplà-Gomis, Mikel L. Forcada, Cristian
                  García-Romero, Taja Kuzman, Nikola Ljubešić, Rik van Noord,
                  Leopoldo Pla Sempere, Gema Ramírez-Sánchez, Peter Rupnik, Vít
                  Suchomel, Antonio Toral, Tobias van der Werff, and Jaume
                  Zaragoza. 2022. MaCoCu: Massive collection and curation of
                  monolingual and bilingual data: focus on under-resourced
                  languages. In Proceedings of the 23rd Annual Conference of the
                  European Association for Machine Translation, pages 303–304,
                  Ghent, Belgium. European Association for Machine Translation
                </p>
                <CopyToClipboard text={decodeURIComponent(bibtexEncoded)}>
                  <div
                    className="copy-button"
                    onClick={() => toast.success("Copied to clipboard!")}
                  >
                    Copy BibTex{" "}
                    <img className="copy-icon" src={CopyIcon} alt="copy-icon" />
                  </div>
                </CopyToClipboard>
              </div>
            </div>
            <div className="macocu-poster-container">
              <a href={MacocuPDF} download>
                <img
                  className="macocu-poster"
                  src={MacocuPoster}
                  alt="macocu-poster"
                />
              </a>
            </div>
          </div>
          <div className="cite-macocu-card"></div>
          <div className="papers-titles">
            <button
              className={
                publication
                  ? "publications--title title-active"
                  : "publications--title"
              }
              onClick={() => {
                setPublication(true);
                setPresentation(false);
              }}
            >
              Publications
            </button>
            <button
              className={
                presentation
                  ? "presentations--title title-active"
                  : "presentations--title"
              }
              onClick={() => {
                setPresentation(true);
                setPublication(false);
              }}
            >
              Presentations
            </button>
          </div>
          <div className="publications">
            {publication &&
              papers.map((paper) => {
                return paper.type === "paper" ? (
                  <Paper
                    title={paper.title}
                    base={paper.in}
                    where={paper.where}
                    authors={paper.authors}
                    tags={paper.tags}
                    date={paper.date}
                    pdf={paper.pdf}
                    bibtex={paper.bibtex}
                    paperLink={paper.paperLink}
                    type={paper.type}
                  />
                ) : (
                  ""
                );
              })}
          </div>
          <div className="presentations">
            {presentation &&
              papers.map((paper) => {
                return paper.type === "presentation" ? (
                  <Paper
                    title={paper.title}
                    base={paper.in}
                    where={paper.where}
                    authors={paper.authors}
                    tags={paper.tags}
                    date={paper.date}
                    type={paper.type}
                    paperLink={paper.paperLink}
                    presentationUrl={paper.presentationUrl}
                  />
                ) : (
                  ""
                );
              })}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
