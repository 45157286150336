import React, { useState } from "react";
import { DropdownList } from "react-widgets";
import DownloadCard from "../download-card/download-card.component";
import CORPORA from "../download-card/corpora";
import IntroColumns from "../intro-columns/intro-columns.component";
import "react-widgets/styles.css";
import "./corpora-section.styles.css";

function CorporaSection() {
  const [value, setValue] = useState({ id: "sq", language: "Albanian" });
  const corpora = CORPORA;

  return (
    <div className="corpora-section--wrapper">
      <div className="corpora-section--container">
        <IntroColumns />
        <h2 className="corpora-title">Corpora</h2>
        <p className="corpora-section--paragraph">
          There have been 2 different releases of MaCoCu corpora. Some languages
          were released in both and some just in the second one. <br /> The
          processing pipeline changed among versions to bring fixes, improved
          filtering and richer metadata.
        </p>
        <h3 className="select-language--title">Select a language</h3>
        <DropdownList
          className="dropdown-list"
          dataKey="id"
          textField="language"
          value={value}
          defaultValue="bg"
          onChange={(nextValue) => {
            setValue(nextValue);
          }}
          data={[
            { id: "sq", language: "Albanian" },
            { id: "bs", language: "Bosnian" },
            { id: "bg", language: "Bulgarian" },
	    { id: "ca", language: "Catalan" },
            { id: "hr", language: "Croatian" },
            { id: "nl", language: "Dutch" },
            { id: "en", language: "English" },
	    { id: "el", language: "Greek" },
            { id: "is", language: "Icelandic" },
            { id: "mk", language: "Macedonian" },
            { id: "mt", language: "Maltese" },
            { id: "me", language: "Montenegrin" },
            { id: "sr", language: "Serbian" },
            { id: "sl", language: "Slovene" },
            { id: "es", language: "Spanish" },
            { id: "tr", language: "Turkish" },
	    { id: "uk", language: "Ukrainian" },
          ]}
        />
        <div className="language-results-and--language-listing">
          <div className="language-results--container">
            {corpora.map((lang) =>
              lang.source === value.id ? (
                <DownloadCard
                  name={lang.name}
                  sentences={lang.sentences}
                  tokens={lang.tokens}
                  size={lang.size}
                  url={lang.url}
                  corsetUrl={lang.corsetUrl}
                  source={value.language}
                  target={lang.target}
                  tags={lang.tags}
                  language={lang.language}
                />
              ) : lang.target === value.id ? (
                <DownloadCard
                  name={lang.name}
                  sentences={lang.sentences}
                  size={lang.size}
                  url={lang.url}
                  corsetUrl={lang.corsetUrl}
                  source={lang.source}
                  target={value.language}
                  tags={lang.tags}
                  language={lang.language}
                />
              ) : (
                ""
              )
            )}
          </div>
          <div className="available-languages">
            <div className="monolingual-corpora">
              <h3 className="monolingual-corpora-title">Our corpora</h3>
              <ul>
                <li
                  onClick={() => setValue({ id: "sq", language: "Albanian" })}
                >
                  Albanian
                </li>
                <li onClick={() => setValue({ id: "bs", language: "Bosnian" })}>
                  Bosnian
                </li>
                <li
                  onClick={() => setValue({ id: "bg", language: "Bulgarian" })}
                >
                  Bulgarian
                </li>
		<li
                  onClick={() => setValue({ id: "ca", language: "Catalan" })}
                >
                  Catalan
                </li>
                <li
                  onClick={() => setValue({ id: "hr", language: "Croatian" })}
                >
                  Croatian
                </li>
                <li onClick={() => setValue({ id: "nl", language: "Dutch" })}>
                  Dutch
                </li>
                <li onClick={() => setValue({ id: "en", language: "English" })}>
                  English
                </li>
		<li
                  onClick={() => setValue({ id: "el", language: "Greek" })}
                >
                  Greek
                </li>
                <li
                  onClick={() => setValue({ id: "is", language: "Icelandic" })}
                >
                  Icelandic
                </li>
                <li
                  onClick={() => setValue({ id: "mk", language: "Macedonian" })}
                >
                  Macedonian
                </li>
                <li onClick={() => setValue({ id: "mt", language: "Maltese" })}>
                  Maltese
                </li>
                <li
                  onClick={() =>
                    setValue({ id: "me", language: "Montenegrin" })
                  }
                >
                  Montenegrin
                </li>
                <li onClick={() => setValue({ id: "sr", language: "Serbian" })}>
                  Serbian
                </li>
                <li onClick={() => setValue({ id: "sl", language: "Slovene" })}>
                  Slovene
                </li>
                <li onClick={() => setValue({ id: "es", language: "Spanish" })}>
                  Spanish
                </li>
                <li onClick={() => setValue({ id: "tr", language: "Turkish" })}>
                  Turkish
                </li>
		<li
                  onClick={() => setValue({ id: "uk", language: "Ukrainian" })}
                >
                  Ukrainian
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorporaSection;
