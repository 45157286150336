import EUFlag from "../../assets/eu_flag.svg";
import HorizonLogo from "../../assets/horizon.svg";
import UKRILogo from "../../assets/UKRIlogo.png";
import HPLTLogo from "../../assets/hplt-logo-crowd.svg";
import MacocuLogo from "../../assets/logo.svg";

import "./CrowdFooter.styles.css";

function CrowdFooter() {
  return (
    <div className="footer-wrapper-crowd">
      <div className="footer-crowd">
        <div className="footer-information">
          <div className="eu-financing-p">
            <a href="https://macocu.eu" target="_blank" rel="noreferrer">
              <img
                src={MacocuLogo}
                alt="horizon logo"
                className="macocu-logo"
              />
            </a>
            <div className="eu-container">
              <img alt="european-union-logo" src={EUFlag}></img>
              <a
                href="https://ec.europa.eu/inea/en/connecting-europe-facility"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <p>
                  Co-financed by the Connecting Europe Facility of the European
                  Union
                </p>{" "}
              </a>
            </div>
          </div>

          <p className="footer-info-last-p">
            This action has received funding from the European Union's
            Connecting Europe Facility 2014-2020 - CEF Telecom, under Grant
            Agreement No. INEA/CEF/ICT/A2020/2278341. This communication
            reflects only the author's view. The Agency is not responsible for
            any use that may be made of the information it contains.
          </p>
        </div>
        <div className="funding-hplt">
          <div
            className="hpl
          t-macocu-logos"
          >
            <a href="https://hplt-project.org" target="_blank" rel="noreferrer">
              <img src={HPLTLogo} alt="UKRI Logo" className="hplt-logo" />
            </a>
          </div>
          <div className="funding-img">
            <a
              href="https://cordis.europa.eu/project/id/101070350"
              target="_blank"
              rel="noreferrer"
            >
              <img src={HorizonLogo} alt="horizon logo" />
            </a>
            <a href="https://www.ukri.org/" target="_blank" rel="noreferrer">
              <img src={UKRILogo} alt="UKRI Logo" className="ukri-logo" />
            </a>
          </div>
          <p className="footer-info-last-p">
            This project has received funding from the European Union’s Horizon
            Europe research and innovation programme under grant agreement No
            101070350 and from UK Research and Innovation (UKRI) under the UK
            government’s Horizon Europe funding guarantee [grant number
            10052546]
          </p>
        </div>{" "}
      </div>
    </div>
  );
}

export default CrowdFooter;
