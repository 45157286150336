import "./intro-columns.styles.css";

export default function IntroColumns() {
  return (
    <div className="intro-columns">
      <h2 className="intro-title">MaCoCu's Corpora at a glance</h2>
      <div className="column-container">
        <div className="first-column-paragraph column">
          MaCoCu corpora links below will get you to single pages where each corpus is explained in detail.
           A brief explantation of the main steps to build them is given here.
         <br/>
          1) MaCoCu corpora, monolingual and bilingual, are obtained by crawling
          extended internet top-level domains, eg. the .hr or .sl domains, in
          2021/2022. 
          <br/>
          2) Texts from crawled websites are extracted and processed 
          to provide high-quality web corpora. We take care removing boilerplate, duplicates, very short texts, 
          text in non-targeted languages, very noisy texts, bad translations, etc. 
        </div>
        <div className="second-column-paragraph column">
          3) Texts are, then, enriched with metadata and delivered in convenient
          formats which allow filtering them based on text quality and other
          criteria. 
          <br/>
          This makes MaCoCu corpora highly useful for corpus
          linguistics as well as for training language models, machine
          translation models and other language technologies. 
          <br/>
          Monolingual corpora are delivered in XML (and sometimes TSV) formats while bilingual corpora
          are released in TXT and TMX formats. 
          <br/>
          Metadata covers a wide range of
          information that may appear at document, paragraph or sentence level
        </div>
        <div className="third-column-rows">
          <div className="paragraph-third-column row">
            <span className="row-title">At document level:</span> title, crawl
            date, url, web domain, file type of the original document,
            distribution of languages inside the document, and a fluency score
            based on a language model.
          </div>
          <div className="paragraph-third-column row">
            <span className="row-title">At paragraph level:</span> ID, type
            (heading/not heading), quality and fluency scores, language,
            translation direction and human vs machine translation in the case of bilingual corpora, language
            variant for some languages and sensitive information.
          </div>
          <div className="paragraph-third-column row">
            <span className="row-title"> At sentence level: </span> URL, ID,
            simhash + quality score for (near) duplicates, language, fluency
            score, sensitive information.
          </div>
        </div>
      </div>
    </div>
  );
}
