import React from "react";
import "./download-card.styles.css";
import CorsetIcon from "../../icons/corset.svg";

function DownloadCard(props) {
  const { source, target, tags, language, sentences, tokens,  size, corsetUrl, url } =
    props;
  return (
    <div className="download-card--wrapper">
      <div className="card-tags">
        {source && !target ? (
          <div className="download-card--tag">
            <p>MONO</p>
          </div>
        ) : (
          <div className="download-card--tag">
            <p>PARALLEL</p>
          </div>
        )}
        {tags.map((tag) => (
          <div className="download-card--tag">     
            <p>{tag}</p>
	  </div>
         ))}        
      </div>

      <div className="corpora-type--tag">
        <h2 className="card-language">{language}</h2>
	{sentences && 
          <p className="sentences">
            {sentences > 1000000
              ? `${Math.floor(sentences / 1000000)}M`
              : sentences > 1000
              ? `${Math.floor(sentences / 1000)}K`
              : sentences}{" "}
            sentences
        </p>
        }
	{tokens && 
          <p className="tokens">
  	    {tokens > 1000000
              ? `${Math.floor(tokens / 1000000)}M`
              : sentences > 1000
              ? `${Math.floor(tokens / 1000)}K`
              : tokens}{" "}
            tokens
          </p>
	}

        <p className="download-size">
          {size > 1000
            ? `${Math.floor(size / 1000)}GB`
            : `${Math.floor(size)}MB`}{" "}
        </p>
      </div>
      <a
        className="corset-button"
        href={corsetUrl}
        target="_blank"
        rel="noreferrer"
      >
        Explore in Corset{" "}
        <img src={CorsetIcon} className="corset-icon" alt="corset-icon" />
      </a>
      <a
        className="download-button"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        DOWNLOAD
      </a>
    </div>
  );
}

export default DownloadCard;
