const TEAM = [
  {
    name: "Marta Bañón",
    role: "Dev and maintainer of Bifixer, Bicleaner and external components",
    affiliation: "Prompsit",
    linkedin: "https://www.linkedin.com/in/martabanong/",
    github: "https://github.com/mbanon",
    profile: require("../../assets/team-pictures/marta-banon.png"),
  },
  {
    name: "Miquel Esplà",
    role: "MaCoCu project coordinator and code base coordinator",
    affiliation: "University of Alicante",
    linkedin: "",
    github: "https://github.com/mespla",
    profile: require("../../assets/team-pictures/miquel-espla.png"),
    website:
      "https://cvnet.cpd.ua.es/curriculum-breve/en/espla-gomis-miquel/16262",
  },
  {
    name: "Mikel Forcada",
    role: "MaCoCu's R&D senior advisor",
    affiliation: "Universitat d'Alacant",
    linkedin: "",
    github: "https://github.com/mlforcada",
    profile: require("../../assets/team-pictures/mikel-forcada.png"),
    website:
      "https://cvnet.cpd.ua.es/curriculum-breve/en/forcada-zubizarreta-mikel-lorenzo/18437",
  },
  {
    name: "Aarón Galiano",
    role: "Developer and mantainer of bitextor",
    affiliation: "University of Alicante",
    linkedin:
      "https://www.linkedin.com/in/aar%C3%B3n-galiano-jim%C3%A9nez-ab8681116/",
    github: "https://github.com/aarongaliano",
    profile: require("../../assets/team-pictures/aaron-galiano.png"),
  },
  {
    name: "Cristian García Romero",
    role: "Developer and mantainer of bitextor",
    affiliation: "University of Alicante",
    linkedin: "https://www.linkedin.com/in/cgr71",
    github: "https://github.com/cgr71ii",
    profile: require("../../assets/team-pictures/cristian-romero.png"),
  },
  {
    name: "Carmen Iniesta",
    role: "MT Developer - Model training & corpus cleaning/curating",
    affiliation: "Prompsit",
    linkedin: "https://www.linkedin.com/in/carmen-iniesta-lopez/",
    github: "https://github.com/Ciniesta",
    profile: require("../../assets/team-pictures/carmen-iniesta.png"),
  },
  {
    name: "Taja Kuzman",
    role: "Corpora curation, data enrichment (English variety, text genre) and corpora dissemination.",
    affiliation: "Jožef Stefan Institute, Ljubljana, Slovenia",
    linkedin: "https://www.linkedin.com/in/taja-kuzman/",
    github: "https://github.com/TajaKuzman",
    profile: require("../../assets/team-pictures/taja-kuzman.png"),
  },
  {
    name: "Nikola Ljubešić",
    role: "MaCoCu crawling and monolingual curation coordination",
    affiliation: "Jožef Stefan Institute",
    linkedin: "",
    github: "https://github.com/nljubesi",
    profile: require("../../assets/team-pictures/nikola-ljubesic.png"),
    website: "https://nljubesi.github.io/",
  },
  {
    name: "Rik van Noord",
    role: "Corpus Evaluation Expert",
    affiliation: "University of Groningen",
    linkedin: "https://nl.linkedin.com/in/rik-van-noord-7b4842101",
    github: " https://github.com/RikVN/",
    profile: require("../../assets/team-pictures/rik-van-noord.png"),
  },
  {
    name: "Sergio Ortiz Rojas",
    role: "Project technical coordinator for corpora curation on behalf of Prompsit",
    affiliation: "Prompsit",
    linkedin: "",
    github: "",
    profile: require("../../assets/team-pictures/avatar.png"),
  },
  {
    name: "Leopoldo Pla Sempere",
    role: "Coordinator of development team of Bitextor and parallel data production",
    affiliation: "University of Alicante",
    linkedin: " https://www.linkedin.com/in/leopoldoplasempere/",
    github: "https://github.com/lpla",
    profile: require("../../assets/team-pictures/leopoldo-pla.png"),
  },

  {
    name: "Gema Ramirez Sánchez",
    role: "General coordinator of Prompsit at MaCoCu + Promotion manager",
    affiliation: "Prompsit",
    linkedin: "https://www.linkedin.com/in/gemaramirezsanchez/",
    github: "",
    profile: require("../../assets/team-pictures/gema-ramirez.png"),
  },
  {
    name: "Peter Rupnik",
    role: "MaCoCu crawling master",
    affiliation: "Jožef Stefan Institute",
    linkedin: "",
    github: "https://github.com/5roop",
    profile: require("../../assets/team-pictures/peter-rupnik.png"),
  },
  {
    name: "Vit Suchomel ",
    role: "MaCoCu crawling master",
    affiliation: "",
    linkedin: "",
    github: "https://github.com/vit-suchomel",
    profile: require("../../assets/team-pictures/avatar.png"),
  },
  {
    name: "Antonio Toral",
    role: "MaCoCu evaluation and data enrichment (DSI, translationese) coordination.",
    affiliation: "University of Groningen",
    linkedin: "",
    github: "https://github.com/antot",
    profile: require("../../assets/team-pictures/antonio-toral.png"),
    website: "https://antoniotor.al/ ",
  },

  {
    name: "Lukas Weymann",
    role: "Web design and developement of Macocu's website",
    affiliation: "Prompsit",
    linkedin: "https://www.linkedin.com/in/lukas-weymann-4237391b0/",
    github: "https://github.com/lukasweymann",
    profile: require("../../assets/team-pictures/lukas-weymann.png"),
  },
  {
    name: "Jaume Zaragoza Bernabeu",
    role: "Bilingual and monolingual data curation engineer",
    affiliation: "Prompsit",
    linkedin: "https://www.linkedin.com/in/zjaume/",
    github: "https://github.com/ZJaume",
    profile: require("../../assets/team-pictures/avatar.png"),
  },
];

export default TEAM;
