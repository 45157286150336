import FirstIllustration from "../../assets/girl_tagging_sentences.svg";
import DownloadIcon from "../../icons/download-icon.svg";
import InfoIcon from "../../icons/info.svg";
import CheckIcon from "../../icons/check-icon.svg";
import { ChevronDown } from "react-feather";
import { useState } from "react";
import SecondReport from "../../../src/assets/second-report.pdf";

import "./first-section.styles.css";

function FirstSection() {
  const [evalOpen, setEvalOpen] = useState(false);

  return (
    <div className="first-section">
      <div className="first-section--container">
        <div className="first-part--first-section">
          <div className="first-section--text">
            <h1 className="title--first-section">
              High-quality corpora for under-resourced languages
            </h1>
            <div className="first-section--buttons" style={{ display: "flex" }}>
              <a
                href="#corpora-section"
                className="get-corpora--button"
                id="the-project"
              >
                GET CORPORA
              </a>
              <div
                className="eval-reports-container"
                onClick={() => setEvalOpen(!evalOpen)}
              >
                <div
                  className="eval-reports-button"
                  style={{ display: "flex" }}
                >
                  EVALUATION REPORT <ChevronDown />
                </div>
                <div
                  className={evalOpen ? "eval-reports" : "hidden-reports"}
                  onClick={() => setEvalOpen(!evalOpen)}
                >
                  <a
                    href="https://www.dlsi.ua.es/~mespla/MaCoCu_evaluation_report.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="report first-report">
                      First Evaluation Report
                    </div>
                  </a>
                  <a href={SecondReport} target="_blank" rel="noreferrer">
                    <div className="report">Second Evaluation Report</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <img
            className="first-section--illustration"
            src={FirstIllustration}
            alt="Girl tagging sentences illustration"
          ></img>
        </div>
        <div className="the-project">
          <h2 className="project--title">What is MaCoCu?</h2>
          <div className="project--info">
            <div className="project-info--single">
              <img
                src={DownloadIcon}
                alt="download-icon"
                className="icon first-section-icon"
              />
              <h3 className="project-info--title">INTERNATIONAL SOURCES</h3>
              <p className="project-info--text">
                We collect data across the internet in order to retrieve
                sentences in any of the under-resourced languages of the
                European Union
              </p>
            </div>
            <div className="project-info--single">
              <img
                src={CheckIcon}
                alt="filter-icon"
                className="icon first-section-icon"
              />
              <h3 className="project-info--title">TOP-TIER CORPORA</h3>
              <p className="project-info--text">
                We use advanced filtering techniques to obtain the finest
                results among all the crawled data
              </p>
            </div>
            <div className="project-info--single">
              <img
                src={InfoIcon}
                alt="info-icon"
                className="icon first-section-icon"
              />
              <h3 className="project-info--title">EXTRA KNOWLEDGE</h3>
              <p className="project-info--text" id="corpora-section">
                Our corpora is shipped with additional information for each
                sentence, like language variants or domain identification
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
