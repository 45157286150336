import "./member-card.styles.css";
import LinkedinSquare from "../../icons/linkedin.svg";
import GithubSquare from "../../icons/github-square.svg";

export default function MemberCard(props) {
  const { name, role, affiliation, linkedin, github, profile } = props;
  return (
    <div className="member-card">
      <img src={profile} alt={name} className="member-profile-picture" />
      <div className="member-data">
        <h2 className="member-name">{name}</h2>

        <p className="member-role">{role}</p>

        <p className="affiliation">{affiliation}</p>
        <div className="member-icons">
          {linkedin ? (
            <a
              className="member-linkedin-link icon-member"
              href={linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="member-linkedin"
                src={LinkedinSquare}
                alt="linkedin-icon"
              />
            </a>
          ) : (
            ""
          )}
          {github ? (
            <a
              className="member-github-link icon-member"
              href={github}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="member-github"
                src={GithubSquare}
                alt="github-icon"
              />
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
