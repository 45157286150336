import "./process-section.styles.css";
import ProcessCard from "../process-card-component/process-card.component";
import { MONOLINGUAL, PARALLEL } from "./process.js";

export default function ProcessSection() {
  const monolingual = MONOLINGUAL;
  const parallel = PARALLEL;
  return (
    <div className="process-section">
      <h1 className="process-section--title">
        What is the corpora creation process?
      </h1>
      <p className="process-section--paragraph">
        It depends. The corpora creation process will differ depending on
        whether we wish to obtain monolingual or parallel corpora and on the corpora version. A detailed description is provided in the Clarin repo where corpora are linked in the previous section. We provide here an overview of the process:
      </p>
      <div className="processes-container">
        <div className="process-monolingual--corpora">
          <h2 className="monolingual-cards--title">monolingual corpora:</h2>
          {monolingual.map((item) => {
            return (
              <ProcessCard
                key={item.key}
                number={item.number}
                title={item.title}
                type={item.type}
                text={item.text}
              />
            );
          })}
        </div>
        <div className="process-parallel--corpora">
          <h2 className="parallel-cards--title">parallel corpora:</h2>
          {parallel.map((item) => {
            return (
              <ProcessCard
                key={item.key}
                number={item.number}
                title={item.title}
                type={item.type}
                text={item.text}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
