import { useState } from "react";
import MacocuLogo from "../../assets/logo.svg";
import "./navbar-mobile.styles.css";
import { Spin as Hamburger } from "hamburger-react";
import { NavLink } from "react-router-dom";

export default function NavbarMobile() {
  const [isOpen, setOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const changeShadow = () => {
    if (window.scrollY > 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeShadow);
  return (
    <div
      className={
        navbar
          ? "navbar-mobile--wrapper navbar-active"
          : "navbar-mobile--wrapper"
      }
    >
      <div className="navbar-mobile">
        <NavLink to="/">
          <img
            src={MacocuLogo}
            alt="macocu-logo"
            className="macocu-logo--mobile"
          />
        </NavLink>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
      <div className={isOpen ? "navbar-mobile--dropdown" : "hidden"}>
        <NavLink
          to="/"
          onClick={() => setOpen(false)}
          className="navbar-link mobile-link"
        >
          Home
        </NavLink>

        <NavLink
          to="/team-page"
          onClick={() => setOpen(false)}
          className="navbar-link mobile-link"
        >
          Team
        </NavLink>

        <NavLink
          to="/papers-page"
          onClick={() => setOpen(false)}
          className="navbar-link mobile-link"
        >
          Papers
        </NavLink>
        <NavLink to="/workshop" className="navbar-link mobile-link">
          CrowdMT Workshop
        </NavLink>
      </div>
    </div>
  );
}
