import "./team-page.styles.css";
import Macocu from "../../icons/macocu.svg";
import MemberCard from "../../components/member-card/member-card.component";
import TEAM from "./team";
import Navbar from "../../components/navbar/navbar.component";
import NavbarMobile from "../../components/navbar-mobile/navbar-mobile.component";
import Footer from "../../components/footer/footer.component";

function TeamPage() {
  const team = TEAM;
  return (
    <>
      <Navbar />
      <div className="team-page--wrapper">
        <NavbarMobile />
        <h1 className="the-team-title">
          The team behind MaCoCu
          <img src={Macocu} className="macocu" alt="macocu-logo" />
        </h1>
        <p className="the-team-text">
          MaCoCu's team is made of people from the partner institutions and
          companies that cover the different roles in the project needed to
          produce high-quality corpora. Please meet us:
        </p>
        <div className="team-members--container">
          {team.map((member) => {
            return (
              <MemberCard
                name={member.name}
                role={member.role}
                affiliation={member.affiliation}
                linkedin={member.linkedin}
                github={member.github}
                profile={member.profile}
              />
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TeamPage;
