import React from "react";
import "./homepage.styles.css";
import CorporaSection from "../../components/corpora-section/corpora-section";
import FirstSection from "../../components/first-section/first-section.component.jsx";
import ExploreSection from "../../components/explore-section/explore-section.component.jsx";
import ProcessSection from "../../components/process-section/process-section";
import NavbarMobile from "../../components/navbar-mobile/navbar-mobile.component";
import Navbar from "../../components/navbar/navbar.component";
import Footer from "../../components/footer/footer.component";

function HomePage() {
  return (
    <>
      <Navbar />
      <div className="homepage-wrapper">
        <NavbarMobile />
        <FirstSection />
        <CorporaSection />
        <ExploreSection />
        <ProcessSection />
      </div>
      <Footer />
    </>
  );
}

export default HomePage;
