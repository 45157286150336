const QUESTIONS = [
  {
    question: "Description of the workshop",
    answer:
      "Welcome to the first edition of the <strong>Workshop on Open Community-Driven Machine Translation</strong>! <br></br>The objective of this workshop is to bring together the experience of researchers and developers in the field of machine translation who are involved in communities organised to <b>build software</b>, data sets and models that are open and available for any potential users.<br></br>This workshop is, in part, inspired by previous events, such as the Workshop on Open-Source Machine Translation, or the International Workshop on Free/Open-Source Rule-Based Machine Translation (Pérez-Ortiz and Sánchez-Martı́nez, 2009; Pérez-Ortiz and Sánchez-Martı́nez, 2011; España-Bonet and Ranta, 2012), open now to a broader scope including all MT paradigms and components.",
  },
  {
    question: "Submission information",
    answer:
      "The workshop accepts submission in two different modalities:<ul><li><strong>Abstracts</strong>: authors can submit abstracts up to 1 page (plus additional pages for references) that summarize the contribution of a work/paper. If accepted, authors will have the option of having their abstracts included in the proceedings of the workshop.</li><li><strong>Papers</strong>: authors can submit papers between 4 and 10 pages (including references but not appendices). If accepted, in addition to presenting their work to the audience, authors will be requested to submit a camera-ready version of paper that will be included in the proceedings of the workshop.</li>Submissions should be formatted according to the EAMT 2023 guidelines and submitted in PDF through the <a href='https://easychair.org/conferences/?conf=crowdmt23' target='_blank' rel='noreferrer'>EasyChair page</a>. One-page abstract submissions must follow the same guidelines, but omitting the abstract section from the templates provided by the organization.</ul>",
  },
  {
    question: "Important dates",
    answer:
      "The expected timeline is: <ul className='answer-list'><li>Workshop paper/abstracts due: 21st April 2023</li><li>Notification of acceptance: 28th April 2023</li><li>Camera-ready papers/abstracts due: 12th May 2023</li><li>Workshop date: 15th June 2023</li></ul>",
  },

  {
    question: "Organisers",
    answer:
      "<ul><li>Miquel Esplà-Gomis, associate professor at the Universitat d’Alacant, Spain.</li><li>Mikel L. Forcada, professor of Computer Languages and Systems at the Universitat d’Alacant, Spain<li>Taja Kuzman, research assistant and PhD student at the Jožef Stefan Institute, Slovenia.<li>Nikola Ljubešić, research associate at Faculty of Computer and Information Science, University of Ljubljana, Slovenia. <li>Rik van Noord, postdoctoral researcher at the University of Groningen, The Netherlands. <li>Gema Ramı́rez-Sánchez, CEO of Prompsit Language Engineering, Spain.<li>Jörg Tiedemann, professor of language technology at the University of Helsinki, Finland. <li>Antonio Toral, assistant professor in Language Technology at the University of Groningen, The Netherlands. </ul>",
  },
  {
    question: "Programme committee",
    answer:
      "<ul><li>Miquel Esplà Gomis (Universitat d’Alacant, Spain)</li><li>Mikel L. Forcada (Universitat d’Alacant, Spain)</li><li>Taja Kuzman (Jožef Stefan Institute, Slovenia)</li><li>Nikola Ljubešić (Jožef Stefan Institute, Slovenia)</li><li>Rik van Noord (University of Groningen, The Netherlands)</li><li>Juan Antonio Pérez-Ortiz (Universitat d’Alacant, Spain)</li><li>Gema Ramı́rez Sánchez (Prompsit Language Engineering, Spain)</li><li>Peter Rupnik (Jožef Stefan Institute, Slovenia)</li><li>Felipe Sánchez-Martínez (Universitat d'Alacant, Spain</li><li>Vı́ctor Manuel Sánchez-Cartagena (Universitat d’Alacant, Spain)</li><li>Jörg Tiedemann (University of Helsinki, Finland)</li><li>Antonio Toral (University of Groningen, The Netherlands)</li><li>Jaume Zaragoza-Bernabeu (Prompsit Language Engineering, Spain)</li></ul>",
  },
  {
    question: "Call for papers",
    answer:
      "The workshop aims to be as open as possible: we invite everybody that works on topics related to <strong>machine translation</strong> in an <b>open and community-driven way</b>. We welcome submissions either as a full paper or just an abstract. The full papers have to present previously unpublished work, but abstract submission is open for anybody who wishes to discuss their relevant work. This work can be previously published, work in progress or simply a proposal for a new project. Topics for submissions include, but are not limited to: <ul> development of machine translation toolkits, platforms and services;<li>collection and curation of datasets that can be used to build machine translation systems;</li><li>automatic post-editing software and data;</li><li>machine translation quality estimation software and data;</li><li>machine translation evaluation;</li><li>integration of machine translation in computer-aided translation;</li><li>use of monolingual resources to improve machine translation; and</li><li>software, data and models licensing issues.</li></ul>",
  },

  {
    question: "References",
    answer:
      "<ul><li>España-Bonet, Cristina and Aarne Ranta, editors. 2012. <i>Proceedings of the Second International Workshop on Free/Open-Source Rule-Based Machine Translation,</i> Gothenburg, Sweden.</li><li>Goyal, Naman, Cynthia Gao, Vishrav Chaudhary, Peng-Jen Chen, Guillaume Wenzek, Da Ju, Sanjana Krishnan, Marc’Aurelio Ranzato, Francisco Guzmán, and Angela Fan. 2021. The flores-101 evaluation benchmark for low-resource and multilingual machine translation.</li><li>Heffernan, Kevin, Onur Çelebi, and Holger Schwenk. 2022. Bitext mining using distilled sentence representations for low-resource languages. In <i>Findings of the Association for Computational Linguistics:</i> EMNLP 2022, pages 2101–2112, Abu Dhabi, United Arab Emirates, December. Association for Computational Linguistics.</li><li>Pérez-Ortiz, Juan Antonio and Felipe Sánchez-Martı́nez, editors. 2009. <i>Proceedings of the First International Workshop on Free/Open-Source Rule-Based Machine Translation,</i> Alacant, Spain.</li><li>Pérez-Ortiz, Juan Antonio and Felipe Sánchez-Martı́nez, editors. 2011. <i>Proceedings of the Second International Workshop on Free/Open-Source Rule-Based Machine Translation,</i> Barcelona, Spain.</li></ul>",
  },
];

export default QUESTIONS;
