import "./program-table.styles.css";
export default function ProgramTable() {
  return (
    <div className="program-table-container">
      <table className="program-table">
        <thead>
          <th>Time</th>
          <th className="table-middle">Event</th>
          <th>Speakers/Pannelists/Chairs</th>
        </thead>
        <tbody>
          <tr>
            <td>9:10-09:20</td>
            <td className="table-bold">Opening</td>
            <td>Gema Ramírez</td>
          </tr>
          <tr>
            <td>9:20-10:10</td>
            <td>
              <b>Invited Talk: </b>Apertium: empowering vulnerable language
              communities through free/open source rule-based machine
              translation
            </td>
            <td>Mikel Forcada</td>
          </tr>
          <tr>
            <td>10:10-10:30</td>
            <td className="table-bold">Abstract presentation</td>
            <td>Chair: Gema Ramírez</td>
          </tr>
          <tr>
            <td></td>
            <td>
              Privacy-Preserving Machine Translation with Personalized
              kNN-Retrieval
            </td>
            <td>Mayumi Ohta</td>
          </tr>
          <tr>
            <td>10:30–11:00</td>
            <td>Coffee Break</td>
            <td></td>
          </tr>
          <tr>
            <td className="table-bold">11:00-12:15</td>
            <td className="table-bold">Paper presentations</td>
            <td>Chair: Miquel Esplà</td>
          </tr>
          <tr>
            <td>11:00-11:25</td>
            <td>
              Creating a parallel Finnish-Easy Finnish dataset from news
              articles
            </td>
            <td>Anna Dmitrieva</td>
          </tr>
          <tr>
            <td>11:25-11:50</td>
            <td>
              Design of an Open-Source Architecture for Neural Machine
              Translation
            </td>
            <td>Seamus Lankford</td>
          </tr>
          <tr>
            <td>11:50-12:15</td>
            <td>
              Training and integration of neural machine translation with MTUOC
            </td>
            <td>Antoni Oliver</td>
          </tr>
          <tr>
            <td>12:15–13:30</td>
            <td>Lunch</td>
            <td></td>
          </tr>
          <tr>
            <td>13:30–14:20</td>
            <td>
              <b>Invited Talk:</b> Machine Translation at Wikipedia
            </td>
            <td>Niklas Laxström and Santhosh Thottingal</td>
          </tr>
          <tr>
            <td className="table-bold">14:20–15:00</td>
            <td className="table-bold">Abstract presentations</td>
            <td>Chair: Jörg Tiedemann</td>
          </tr>
          <tr>
            <td>14:20–15:00</td>
            <td>
              A Python Tool for Selecting Domain-Specific Data in Machine
              Translation
            </td>
            <td>Dimitar Shterionov</td>
          </tr>
          <tr>
            <td>14:20–15:00</td>
            <td>MutNMT, an open-source NMT tool for educational purposes</td>
            <td>Gema Ramírez-Sánchez</td>
          </tr>
          <tr>
            <td>15:00–15:30</td>
            <td>Coffee Break</td>
            <td></td>
          </tr>
          <tr>
            <td className="table-bold">15:30–16:15</td>
            <td className="table-bold">
              Panel on communities and open-source in MT.
            </td>
            <td>
              Panelists: Niklas Laxström+Santhosh Thottingal, Mikel Forcada,
              Miquel Esplà, Jörg Tiedemann and Helena Moniz. Chairs: Antonio
              Toral, Gema Ramírez.
            </td>
          </tr>
          <tr>
            <td>16:15–16:30</td>
            <td className="table-bold">Closing</td>
            <td>Gema Ramírez</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
