import { useState } from "react";
import { GitCommit } from "react-feather";
import "./Question.styles.css";

export default function Question(props) {
  const { question, answer } = props;
  const [qAnswer, showqAnswer] = useState(false);

  function toggleAnswer() {
    return !qAnswer ? showqAnswer(true) : showqAnswer(false);
  }

  return (
    <div className="question">
      <div className="question-title-container" onClick={toggleAnswer}>
        <GitCommit color="#77BABD" size={18} />
        <h3 className="question-title">{question}</h3>
      </div>

      <div className={qAnswer ? "answer-container" : "hidden"}>
        <p
          className="answer-paragraph"
          dangerouslySetInnerHTML={{ __html: answer }}
        ></p>
      </div>
    </div>
  );
}
