import { useState } from "react";
import MacocuLogo from "../../assets/logo.svg";
import { NavLink } from "react-router-dom";

import "./navbar.styles.css";

function Navbar() {
  const [navbar, setNavbar] = useState(false);

  const changeShadow = () => {
    if (window.scrollY > 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeShadow);

  return (
    <div className={navbar ? "navbar navbar-active" : "navbar"}>
      <div className="navbar-container">
        <NavLink to="/">
          <img
            className="macocu-logo--navbar"
            src={MacocuLogo}
            alt="macocu-logo"
          ></img>
        </NavLink>
        <div className="navbar--links">
          <NavLink to="/" className="navbar-link">
            Home
          </NavLink>

          <NavLink to="/team-page" className="navbar-link">
            Team
          </NavLink>

          <NavLink to="/papers-page" className="navbar-link">
            Papers
          </NavLink>
          <NavLink
            to="/workshop"
            className="upcoming-workshop-button navbar-link"
          >
            CrowdMT Workshop
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
