import React from "react";
import "./explore-section.styles.css";
import GirlCoding from "../../assets/cool_girl_coding.svg";
import GitHubIcon from "../../icons/github-icon.svg";

function ExploreSection() {
  return (
    <div className="explore--section">
      <div className="explore-section--left">
        <h2 className="explore-section--title">
          Feel free to explore, review and contribute to our code
        </h2>
        <a
          href="https://github.com/bitextor/bitextor"
          target="_blank"
          className="explore-bitextor--button"
          rel="noreferrer"
        >
          <span src={GitHubIcon}>
            <img src={GitHubIcon} alt="github icon " className="icon" />
          </span>
         Bitextor
        </a>
        <a
          href="https://github.com/bitextor/monotextor"
          target="_blank"
          className="explore-bitextor--button"
          rel="noreferrer"
        >
          <span src={GitHubIcon}>
            <img src={GitHubIcon} alt="github icon " className="icon" />
          </span>
          Monotextor
        </a>
        <a
          href="https://github.com/macocu"
          target="_blank"
          className="explore-macocu--button"
          rel="noreferrer"
        >
          <span src={GitHubIcon}>
            <img src={GitHubIcon} alt="github icon " className="icon" />
          </span>
          MaCoCu
        </a>
        <a
          id="our-process"
          className="corset-link"
          href="https://corset.paracrawl.eu/"
          target="_blank"
          rel="noreferrer"
        >
          Explore MaCoCu's Corpora →
        </a>
      </div>
      <img
        src={GirlCoding}
        alt="Girl Coding"
        className="girl-coding--img"
      ></img>
    </div>
  );
}

export default ExploreSection;
