import "./process-card.styles.css";

export default function ProcessCard(props) {
  const { number, title, text, type } = props;
  return (
    <div
      className={
        type === "mono"
          ? "process-card monolingual-card"
          : "process-card parallel-card"
      }
    >
      <div className="enumeration-and-title">
        <p className="process-card-number">{number}</p>
        <h2 className="process-card-title">{title.toUpperCase()}</h2>
      </div>
      <p
        dangerouslySetInnerHTML={{ __html: text }}
        className="process-card--text"
      ></p>
    </div>
  );
}
