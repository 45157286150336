import QUESTIONS from "./questions";
import Question from "../../components/question/Question";
import { useState } from "react";
import { Spin as Hamburger } from "hamburger-react";
//// PRESENTATIONS
import ApertiumPresentation from "../../assets/presentations/apertium_crowdmt.pdf";
import NMTPresentation from "../../assets/presentations/neural-machine-translation.pdf";
import ParallelFinish from "../../assets/presentations/parallel-finnish.pdf";
import DomainData from "../../assets/presentations/domain-data.pdf";
import MutNMT from "../../assets/presentations/mutnmt.pdf";
import MTUOC from "../../assets/presentations/MTUOC.pdf";
import Proceedings from "../../assets/presentations/proceedings.pdf";

import "./workshop-page.styles.css";
import ProgramTable from "../../components/program-table/program-table.component";
import CrowdFooter from "../../components/crowd-footer/CrowdFooter";

export default function WorkshopPage() {
  const questions = QUESTIONS;
  const [isOpen, setOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const changeShadow = () => {
    if (window.scrollY > 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeShadow);
  return (
    <div className="workshop-page">
      <nav
        className={
          navbar ? "workshop-navbar workshop-navbar-active" : "workshop-navbar"
        }
      >
        <p className="workshop-logo">
          crowd<span className="capital">mt</span>
          <span>.</span>
        </p>
        <div className="together">
          <p>Jointly organised by:</p>
          <div className="workshop-nav-links">
            <a href="https://macocu.eu/" target="_blank" rel="noreferrer">
              Macocu
            </a>
            <a
              href="https://hplt-project.org/"
              target="_blank"
              rel="noreferrer"
            >
              HPLT
            </a>
          </div>
        </div>
      </nav>
      <div
        className={
          navbar
            ? "workshop-navbar-mobile--wrapper workshop-navbar-active"
            : "workshop-navbar-mobile--wrapper"
        }
      >
        <div className="workshop-navbar-mobile">
          <p className="workshop-logo">
            crowd<span className="capital">mt</span>
            <span>.</span>
          </p>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
        <div className={isOpen ? "workshop-navbar-mobile--dropdown" : "hidden"}>
          <p className="navbar-link mobile-link grey-navbar-mobile">
            Jointly orgnised by:
          </p>
          <a
            href="https://macocu.eu/"
            target="_blank"
            rel="noreferrer"
            onClick={() => setOpen(false)}
            className="navbar-link mobile-link"
          >
            Macocu
          </a>

          <a
            href="https://hplt-project.org/"
            target="_blank"
            rel="noreferrer"
            onClick={() => setOpen(false)}
            className="navbar-link mobile-link"
          >
            HPLT
          </a>
        </div>
      </div>
      <div className="workshop-banner">
        <h1>Workshop on Open Community-Driven Machine Translation</h1>
        <a
          href="https://events.tuni.fi/eamt23/"
          className="banner-link"
          target="_blank"
          rel="noreferrer"
        >
          An EAMT 2023 Workshop. 15th June 2023, Tampere, Finland.
        </a>
      </div>
      <div className="workshop-container">
        <div className="workshop-page-copy">
          <p>
            The 1st edition of the Workshop on Open Community-Driven Machine
            Translation (CrowdMT 2023) will be held in Tampere, Finland, on June
            15, 2023, co-located with EAMT 2023.{" "}
          </p>
          <h2> Registration</h2>
          <p className="paragraph">
            {" "}
            Please register for CrowdMT workshop through the{" "}
            <a
              href="https://events.tuni.fi/eamt23/registration"
              target="_blank"
              rel="noreferrer"
            >
              EAMT website
            </a>
            .
          </p>
          <h2>Invited speakers</h2>
          <div className="invited-speaker">
            <p className="paragraph">
              {" "}
              <a
                href="https://www.linkedin.com/in/niklaslaxstrom/?originalSubdomain=fi"
                target="_blank"
                rel="noreferrer"
              >
                Niklas Laxström
              </a>{" "}
              and{" "}
              <a
                href="https://www.linkedin.com/in/santhoshthottingal/"
                target="_blank"
                rel="noreferrer"
              >
                Santhosh Thottingal
              </a>{" "}
              on behalf of the{" "}
              <a
                href="https://wikimediafoundation.org"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Wikimedia Foundation
              </a>
              , the largest multilingual project in the world, among other great
              achievements.
            </p>
            <h3 className="talk-title">Machine Translation at Wikipedia</h3>
            <p className="talk-paragraph">
              Wikipedia, the multilingual encyclopedia available in over 320
              languages, uses machine translation technology primarily for
              article translation. The translation process involves an
              integrated tool that utilizes various machine translation services
              to provide initial translations, which are then refined by editors
              before publication. To date, approximately 1.5 million articles
              have been translated.. This presentation aims to introduce a
              human-in-the-loop product design, highlighting the provision of
              high-quality rich text translations through text-only machine
              translation, coupled with manual curation facilitated by human
              edits. Additionally, we will share insights and analytics
              pertaining to translation quality and translators. The discussion
              will encompass the machine translation engines employed, ranging
              from free and open-source systems to self-hosted services and
              external paid APIs. Lastly, we will present the optimization
              techniques employed to scale machine translation models in order
              to meet the performance requirements of Wikipedia.
            </p>
          </div>
          <div className="invited-speaker">
            <p className="paragraph">
              <a
                href="https://www.linkedin.com/in/mikel-forcada-b3248462/"
                target="_blank"
                rel="noreferrer"
              >
                Mikel Forcada
              </a>{" "}
              on behalf of{" "}
              <a href="https://apertium.org" target="_blank" rel="noreferrer">
                Apertium
              </a>
              , a free and open-source platform for machine translation, and a
              true open-source and community-driven project.
            </p>
            <a href={ApertiumPresentation} target="_blank" rel="noreferrer">
              <h3 className="talk-title">
                Apertium: empowering vulnerable language communities through
                free/open source rule-based machine translation
              </h3>
            </a>
            <p className="talk-paragraph">
              Language technologies, including machine translation, are crucial
              in our multilingual world, particularly since a growing fraction
              of communication takes place online. For many languages,
              reasonably useful machine translation does not yet exist, or if
              there is, it is often in the hands of one or a few companies —with
              honourable exceptions. In the age of neural machine translation
              and deep learning, a concentration of translation power occurs:
              only a few privileged companies (a) possess the necessary
              resources to collect and curate bilingual corpora which they do
              not publish, (b) are able to train and execute neural machine
              translation models which they usually do not publish, and (c) do
              so on massive computers that only they can afford, generating
              large amounts of greenhouse gases and heat and leaving behind the
              waste generated when building their computing facilities. This
              generates a kind of technological language injustice through
              dynamics of technological disempowerment in the communities of the
              affected languages. As a result, speakers in technology-deprived
              or dependent communities experience an incomplete citizenship, as
              citizenship is built and articulated through communication.
              Apertium, a free/open-source rule-based machine translation
              platform, was born in 2005 when statistical machine translation,
              the precursor of neural machine translation, was blooming.
              Apertium was originally designed to deal with closely related
              languages such as Spanish and Catalan, but the free/open-source
              licensing attracted a community that started to create systems for
              other language pairs, encoding in open dictionaries and rules
              explicit knowledge about their languages, knowledge that can be
              used to create new machine translation systems or other language
              technologies. In this talk, I will argue in favour of a
              free/open-source incarnation of such a vintage but frugal and
              sustainable technology as rule-based machine translation as a way
              for vulnerable language communities to technologically empower
              themselves.
            </p>
          </div>
          <h2 className="accepted-papers-title">
            Accepted papers and abstracts
          </h2>
          <ul className="workshop-list">
            <li>
              Javad Pourmostafa Roshan Sharami, Dimitar Shterionov and Pieter
              Spronck
              <br />{" "}
              <a href={DomainData} target="_blank" rel="noreferrer">
                {" "}
                <i>
                  A Python Tool for Selecting Domain-Specific Data in Machine
                  Translation
                </i>
              </a>
            </li>
            <li>
              Antoni Oliver
              <br />{" "}
              <a href={MTUOC} target="_blank" rel="noreferrer">
                <i>
                  Training and integration of neural machine translation with
                  MTUOC
                </i>
              </a>
            </li>
            <li>
              Seamus Lankford, Haithem Afli Afli and Andy Way
              <br />{" "}
              <a href={NMTPresentation} target="_blank" rel="noreferrer">
                <i>
                  Design of an Open-Source Architecture for Neural Machine
                  Translation
                </i>
              </a>
            </li>
            <li>
              Anna Dmitrieva and Aleksandra Konovalova
              <br />
              <a href={ParallelFinish} target="_blank" rel="noreferrer">
                <i>
                  Creating a parallel Finnish-Easy Finnish dataset from news
                  articles
                </i>
              </a>
            </li>
            <li>
              Gema Ramírez-Sánchez
              <br />
              <a href={MutNMT} target="_blank" rel="noreferrer">
                <i>MutNMT, an open-source NMT tool for educational purposes</i>
              </a>
            </li>
            <li>
              Mayumi Ohta
              <br />
              <i>
                Privacy-Preserving Machine Translation with Personalized
                kNN-Retrieval
              </i>
            </li>
          </ul>
          <a href={Proceedings} target="_blank" rel="noreferrer">
            <h3 className="full-proceedings">
              Full Proceedings of the 1st Workshop on Open Community-Driven
              Machine Translation{" "}
            </h3>
          </a>
          <h2 className="workshop-info-title">Workshop information </h2>
          <h3>Tentative program</h3>
          <ProgramTable />
          {questions.map((q) => {
            const { question, answer } = q;
            return <Question question={question} answer={answer} />;
          })}
        </div>
      </div>
      <CrowdFooter />
    </div>
  );
}
